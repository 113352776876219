<template>
  <div class="debt-installments">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview>
          <template v-slot:preview>
            <b-row class="mb-5">
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Từ"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.fromDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Đến"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.toDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="3">
                <Autosuggest
                  :model="searchInstallment"
                  :suggestions="filteredOptionsInstallment"
                  placeholder="dịch vụ trả góp"
                  :limit="200"
                  @select="onSelectedInstallment"
                  @change="onInputChangeInstallment"
                  suggestionName="suggestionName"
                />
              </b-col>
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchMainData()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <b-table
                  :items="mainList.dataset"
                  :fields="tableFields"
                  :busy="onLoadingList"
                  class="table-bordered table-hover"
                >
                  <template slot="thead-top">
                    <tr>
                      <th class="text-center">STT</th>
                      <th class="text-center">Dịch vụ trả góp</th>
                      <th class="text-center" colspan="2">
                        Phát sinh trong kì
                      </th>
                      <th class="text-center" colspan="2">Số dư cuối kì</th>
                    </tr>
                  </template>
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10" />
                  </template>
                  <template v-slot:cell(index)="row">
                    {{ row.index + 1 + getOffsetItem }}
                  </template>
                  <template v-slot:cell(debtAmount)="row">
                    {{ convertPrice(row.item.debtAmount) }}
                  </template>
                  <template v-slot:cell(paidAmount)="row">
                    {{ convertPrice(row.item.paidAmount) }}
                  </template>
                  <template v-slot:cell(debtSum)="row">
                    <span v-if="row.item.debtAmount > row.item.paidAmount">
                      {{
                        convertPrice(row.item.debtAmount - row.item.paidAmount)
                      }}
                    </span>
                  </template>
                  <template v-slot:cell(paidSum)="row">
                    <span v-if="row.item.paidAmount > row.item.debtAmount">
                      {{
                        convertPrice(row.item.paidAmount - row.item.debtAmount)
                      }}
                    </span>
                  </template>
                  <template slot="bottom-row">
                    <td colspan="2" class="text-right font-weight-bolder">
                      <span>Tổng</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('debtAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('paidAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{
                        convertPrice(sumCalAmount('debtAmount', 'paidAmount'))
                      }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{
                        convertPrice(sumCalAmount('paidAmount', 'debtAmount'))
                      }}</span>
                    </td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ convertPrice(mainList.total) }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="customPagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { convertPrice } from '@/utils/common';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
  whiteSpace: 'break-spaces',
};

export default {
  data() {
    return {
      title: 'Công nợ dịch vụ trả góp',
      listStores: [{ value: '', text: 'Tất cả cửa hàng' }],
      listInstallments: [{ value: '', text: 'Tất cả dịch vụ trả góp' }],
      optionPaidStatus: [
        { value: '', text: 'Công nợ trả góp' },
        { value: 'true', text: 'Hết nợ' },
        { value: 'false', text: 'Còn nợ' },
      ],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        fromDate: null,
        toDate: null,
        installmentId: null,
      },
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'index',
          label: '[1]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: '[2]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '35%',
          },
        },
        {
          key: 'debtAmount',
          label: 'Nợ\n[3]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
        {
          key: 'paidAmount',
          label: 'Có\n[4]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
        {
          key: 'debtSum',
          label: 'Nợ [Phải thu]\n= 3 - 4',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
        {
          key: 'paidSum',
          label: 'Có [Phải trả]\n= 4 - 3',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredOptionsInstallment: [],
      optionsInstallment: [
        {
          data: [],
        },
      ],
      searchInstallment: '',
      InstallmentSelected: null,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    Autosuggest
  },
  mounted() {
    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  created() {
    this.fetchInstallment();
    this.loadBreadcum();
    this.fetchMainData();

  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        this.onFilter();
      }
    },
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      if (this.searchInstallment.trim() === '') {
        this.installmentSelected = null;
      }
      this.apiParams.page = page;
      this.apiParams.type = this.$route.params.mode;
      this.apiParams.installmentId = this.installmentSelected;

      this.onLoadingList = true;
      ApiService.query(cmdUrl.DebtUrl.debtInstallments, {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
          this.onLoadingList = false;
        });
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    sumCalAmount(key, key2) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          const amount1 = parseFloat(item[key]);
          const amount2 = parseFloat(item[key2]);
          const calVal = amount1 - amount2;
          return sum + (calVal > 0 ? calVal : 0);
        },
        0
      );
    },
    fetchInstallment() {
      ApiService.setHeader();
      ApiService.get(`installments/`).then((response) => {
        const dataset = response.data.data;
        dataset.result.map((element) => {
          const installment = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsInstallment[0].data.push(installment);
        });
        const firstInstallment = {
          id: null,
          name: 'Tất cả dịch vụ',
          suggestionName: 'Tất cả dịch vụ',
        };
        this.optionsInstallment[0].data.unshift(firstInstallment);
        this.filteredOptionsInstallment = [...this.optionsInstallment[0].data];
      });
    },
    onSelectedInstallment(option) {
      this.searchInstallment = option.item.name;
      this.installmentSelected = option.item.id;
    },
    onInputChangeInstallment(text) {
      this.searchInstallment = text;
      const filteredData = this.optionsInstallment[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsInstallment = [...filteredData];
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    getOffsetItem() {
      return (this.apiParams.page - 1) * this.apiParams.pageSize;
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>

<style lang="scss">
.debt-installments {
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }
  .break-line {
    white-space: break-spaces;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
